import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-abd59fc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pdfEngine" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = { class: "btn" }
const _hoisted_5 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_VuePdfEmbed = _resolveComponent("VuePdfEmbed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.pdfEngineParams.showTitle || !_ctx.pdfEngineParams.loadAllPage || _ctx.pdfEngineParams.showScale)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.pdfEngineParams.showTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.pdfEngineParams.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.pdfEngineParams.loadAllPage)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  onClick: _ctx.prev
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("上一页")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (!_ctx.pdfEngineParams.loadAllPage)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 1,
                  onClick: _ctx.next
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("下一页")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.pdfEngineParams.showScale)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 2,
                  onClick: _ctx.zoomA
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("放大")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.pdfEngineParams.showScale)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 3,
                  onClick: _ctx.zoomB
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("缩小")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.pdfEngineParams.loadAllPage)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.pdfEngineParams.numPages, (i) => {
            return (_openBlock(), _createBlock(_component_VuePdfEmbed, {
              class: "pdfembed",
              style: _normalizeStyle(_ctx.scaleFun()),
              source: _ctx.pdfEngineParams.source,
              key: i,
              page: i
            }, null, 8, ["style", "source", "page"]))
          }), 128))
        : (_openBlock(), _createBlock(_component_VuePdfEmbed, {
            key: 1,
            source: _ctx.pdfEngineParams.source,
            style: _normalizeStyle(_ctx.scaleFun()),
            class: "pdfembed",
            page: _ctx.pdfEngineParams.pageNum
          }, null, 8, ["source", "style", "page"]))
    ])
  ]))
}